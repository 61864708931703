exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-about-page-index-tsx": () => import("./../../../src/templates/AboutPage/index.tsx" /* webpackChunkName: "component---src-templates-about-page-index-tsx" */),
  "component---src-templates-announcement-page-index-tsx": () => import("./../../../src/templates/AnnouncementPage/index.tsx" /* webpackChunkName: "component---src-templates-announcement-page-index-tsx" */),
  "component---src-templates-apartments-page-index-tsx": () => import("./../../../src/templates/ApartmentsPage/index.tsx" /* webpackChunkName: "component---src-templates-apartments-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-press-page-index-tsx": () => import("./../../../src/templates/PressPage/index.tsx" /* webpackChunkName: "component---src-templates-press-page-index-tsx" */)
}

