import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect } from 'react'

interface Props {
  children: ReactNode
  location: any
}

export const Transition = memo(function Transition({
  children,
  location,
}: Props) {
  useEffect(() => {
    history.scrollRestoration = 'manual'
  }, [])

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <Aside>
          <SlideIn
            initial={{ scaleY: 1 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 0 }}
            transition={{
              duration: 0.7,
              ease: [0.645, 0.045, 0.355, 1.0],
            }}
          />
          <SlideOut
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 1 }}
            transition={{
              duration: 0.8,
              ease: [0.645, 0.045, 0.355, 1.0],
            }}
          />
        </Aside>

        {children}
      </Main>
    </AnimatePresence>
  )
})

const Main = styled(motion.main)``

const Aside = styled.aside``

const Style = css`
  width: 100%;
  height: 100vh;
  background: ${theme.colors.variants.primaryLight};
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 10002;
`

const SlideIn = styled(motion.div)`
  ${Style}

  transform-origin: bottom;
`

const SlideOut = styled(motion.div)`
  ${Style}

  transform-origin: top;
`
